import { Component } from '@angular/core';
import { RouterOutlet} from '@angular/router';
import {CommonModule} from "@angular/common";
import { ToasterComponent } from '../core/components/toaster/toaster.component';

@Component({
    selector: 'app-root',
    imports: [CommonModule
       ,RouterOutlet,ToasterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'lms';
}
