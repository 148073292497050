import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    /**
     *_authenticationService.toasts
     * @param {AuthenticationService} _authenticationService
     */
    constructor(private _authenticationService: AuthenticationService) {
    }

    /**
     * Add auth header with jwt if user is logged in and request is to api url
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this._authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser?.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser?.token}`,
                    Accept: 'application/json',
                    lang: 'ar'
                }
            });
        }

        return next.handle(request);
    }
}
