<ng-container *ngIf="toasts.length > 0">
  <div class="toast-container position-fixed bottom-0 end-0 p-3">

    <ngb-toast 
    [className]="toasts[0].classname"
    [autohide]="true" (hidden)="removeToast(toasts[0])">
			{{toasts[0].text}}
		</ngb-toast>

  </div>
  
</ng-container>