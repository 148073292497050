import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./tenant-app/modules/auth/auth.module').then(m => m.AuthModule)
    }, 
    {
        path: 'lms',
        loadChildren: () => import('./tenant-app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    }, 
    {
        path: 'student',
        loadChildren: () => import('./tenant-app/modules/student/student.module').then(m => m.StudentModule)
    }, 
    {
        path: 'front',
        loadChildren: () => import('./tenant-app/front/front.module').then(m => m.FrontModule)
    },
    {
        path: '',
        redirectTo: '/front/home-page',
        pathMatch: 'full'
    },
];
