import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from "../service";
import { ToastService } from '../../services/toaster.service';
import { Router } from '@angular/router';


@Injectable()
export class errorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
    public toastService: ToastService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let errMsg;

      if ([401,403].includes(err.status)) {
        this.authenticationService.logout();
        errMsg = 'Unauthorized'
      }

      else if (err.status === 500) {
        errMsg = 'Server Error'
      }
      const error =errMsg|| err?.error?.message || err.statusText;
      this.toastService.show(error, {
        classname: 'bg-danger text-white',
      });
      return throwError(error);
    }))
  }
}



