import { Component, ViewEncapsulation } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ToastService } from '../../services/toaster.service';

@Component({
  selector: 'app-toaster',
  imports: [NgbToastModule,CommonModule],
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss',
  encapsulation: ViewEncapsulation.None,

})
export class ToasterComponent {
  toasts: { text: string; classname: string; delay?: number }[] = [];

  constructor(private toastService: ToastService) {
    // Subscribe to the service's observable
    this.toastService.toasts$.subscribe((toasts) => {
      this.toasts = toasts; // Update the local `toasts` array
    });
  }

  removeToast(toast: any) {
    this.toastService.remove(toast);
  }
}
